// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alphatoncolorsandglazes-js": () => import("./../../../src/pages/alphatoncolorsandglazes.js" /* webpackChunkName: "component---src-pages-alphatoncolorsandglazes-js" */),
  "component---src-pages-alphatonshapes-js": () => import("./../../../src/pages/alphatonshapes.js" /* webpackChunkName: "component---src-pages-alphatonshapes-js" */),
  "component---src-pages-baguetteshapes-js": () => import("./../../../src/pages/baguetteshapes.js" /* webpackChunkName: "component---src-pages-baguetteshapes-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-design-and-innovation-js": () => import("./../../../src/pages/design_and_innovation.js" /* webpackChunkName: "component---src-pages-design-and-innovation-js" */),
  "component---src-pages-download-center-js": () => import("./../../../src/pages/download_center.js" /* webpackChunkName: "component---src-pages-download-center-js" */),
  "component---src-pages-fabrikshapes-js": () => import("./../../../src/pages/fabrikshapes.js" /* webpackChunkName: "component---src-pages-fabrikshapes-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get_in_touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-longotoncolorsandglazes-js": () => import("./../../../src/pages/longotoncolorsandglazes.js" /* webpackChunkName: "component---src-pages-longotoncolorsandglazes-js" */),
  "component---src-pages-longotonshapes-js": () => import("./../../../src/pages/longotonshapes.js" /* webpackChunkName: "component---src-pages-longotonshapes-js" */),
  "component---src-pages-lunchandlearn-js": () => import("./../../../src/pages/lunchandlearn.js" /* webpackChunkName: "component---src-pages-lunchandlearn-js" */),
  "component---src-pages-our-products-js": () => import("./../../../src/pages/our_products.js" /* webpackChunkName: "component---src-pages-our-products-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-terracotta-cladding-australia-js": () => import("./../../../src/pages/terracotta-cladding-australia.js" /* webpackChunkName: "component---src-pages-terracotta-cladding-australia-js" */),
  "component---src-pages-terracotta-rainscreen-explained-js": () => import("./../../../src/pages/terracotta-rainscreen-explained.js" /* webpackChunkName: "component---src-pages-terracotta-rainscreen-explained-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-product-category-details-js": () => import("./../../../src/templates/ProductCategoryDetails.js" /* webpackChunkName: "component---src-templates-product-category-details-js" */),
  "component---src-templates-selected-project-js": () => import("./../../../src/templates/SelectedProject.js" /* webpackChunkName: "component---src-templates-selected-project-js" */)
}

